const Menu = () => {
	const drawerToggle = () => {
		let drawer = document.querySelector(`#hamburger-menu`);

		drawer.addEventListener("click", () => {
			let elmClassList = document.querySelector("#Header").classList;
			if (elmClassList.contains("nav-close")) {
				elmClassList.remove("nav-close");
				elmClassList.add("nav-open");
				drawer.classList.add("active");
			} else if (elmClassList.contains("nav-open")) {
				elmClassList.remove("nav-open");
				elmClassList.add("nav-close");
				drawer.classList.remove("active");
			}
		});
	};

	const drawerClose = () => {
		let maskedArea = document.querySelector(`#masked`);
		let drawer = document.querySelector(`#hamburger-menu`);

		maskedArea.addEventListener("click", () => {
			let elmClassList = document.querySelector("#Header").classList;
			if (elmClassList.contains("nav-close")) {
				elmClassList.remove("nav-close");
				elmClassList.add("nav-open");
				drawer.classList.add("active");
			} else if (elmClassList.contains("nav-open")) {
				elmClassList.remove("nav-open");
				elmClassList.add("nav-close");
				drawer.classList.remove("active");
			}
		});
	};

	const drawerCloseOnly = (target) => {
		target.addEventListener("click", () => {
			let elmClassList = document.querySelector("#Header").classList;
			let drawer = document.querySelector(`#hamburger-menu`);
			if (elmClassList.contains("nav-open")) {
				elmClassList.remove("nav-open");
				elmClassList.add("nav-close");
				drawer.classList.remove("active");
			}
		});
	};

	window.onload = () => {
		const logo = document.querySelector("#logo");
		const courseIntro = document.querySelector("#course-intro");
		drawerCloseOnly(logo);
		drawerCloseOnly(courseIntro);
	};
	window.addEventListener("load", drawerClose);
	window.addEventListener("load", drawerToggle);
};

export default Menu;
